import { removeNumericFormat, numericFormatter } from "react-number-format";

export const parseCurrency = (val: string): number => {
  return (
    parseFloat(
      removeNumericFormat(
        val,
        {
          from: { start: 0, end: 0 },
          to: { start: 0, end: 0 },
          lastValue: "",
        },
        {
          suffix: " €",
          thousandSeparator: " ",
          decimalSeparator: ",",
          allowNegative: true,
        }
      )
    ) || 0
  );
};

export const getCurrency = (val: number): string => {
  return numericFormatter(val.toString(), {
    suffix: " €",
    thousandSeparator: " ",
    decimalSeparator: ",",
    allowNegative: true,
  });
};
