import {
  PropsWithChildren,
  ReactElement,
  createContext,
  useReducer,
} from "react";

import { Account, Recurrent, Statement, Transfer } from "../models";

type AddAccount = { type: "addAccount"; payload: Account };
type AddRecurrent = { type: "addRecurrent"; payload: Recurrent };
type AddStatement = { type: "addStatement"; payload: Statement };
type AddStatements = { type: "addStatements"; payload: Statement[] };
type AddTransfer = { type: "addTransfer"; payload: Transfer };
type AddTransfers = { type: "addTransfers"; payload: Transfer[] };
type DeleteAccount = { type: "deleteAccount"; payload: string };
type DeleteRecurrent = { type: "deleteRecurrent"; payload: string };
type DeleteStatement = { type: "deleteStatement"; payload: string };
type DeleteTransfer = { type: "deleteTransfer"; payload: string };
type ReplaceReccurent = { type: "replaceRecurrent"; payload: Recurrent };
type ReplaceStatement = { type: "replaceStatement"; payload: Statement };
type ReplaceTransfer = { type: "replaceTransfer"; payload: Transfer };
type SetAccount = { type: "setAccount"; payload: Account | null };
type SetAccountId = { type: "setAccountId"; payload: string | null };
type SetAccounts = { type: "setAccounts"; payload: Account[] };
type SetReccurents = { type: "setRecurrents"; payload: Recurrent[] };
type SetStatements = { type: "setStatements"; payload: Statement[] };
type SetStatementsCount = { type: "setStatementsCount"; payload: number };
type SetTransfers = { type: "setTransfers"; payload: Transfer[] };
type SetTransfersCount = { type: "setTransfersCount"; payload: number };

type Action =
  | AddAccount
  | AddRecurrent
  | AddStatement
  | AddStatements
  | AddTransfer
  | AddTransfers
  | DeleteAccount
  | DeleteRecurrent
  | DeleteStatement
  | DeleteTransfer
  | ReplaceReccurent
  | ReplaceStatement
  | ReplaceTransfer
  | SetAccount
  | SetAccountId
  | SetAccounts
  | SetReccurents
  | SetStatements
  | SetStatementsCount
  | SetTransfers
  | SetTransfersCount;

interface AccountState {
  accounts: Account[];
  accountId: string | null;
  account: Account | null;
  statements: Statement[];
  statementsCount: number;
  transfers: Transfer[];
  recurrents: Recurrent[];
  transfersCount: number;
}
const defaultState: AccountState = {
  accounts: [],
  accountId: null,
  account: null,
  statements: [],
  statementsCount: 0,
  transfers: [],
  recurrents: [],
  transfersCount: 0,
};

export interface IAccountContext {
  state: AccountState;
  dispatch: React.Dispatch<Action>;
}

export const AccountContext = createContext<IAccountContext>({
  state: defaultState,
  dispatch: () => {},
});

const AccountReducer = (state: AccountState, action: Action): AccountState => {
  switch (action.type) {
    case "addAccount":
      return {
        ...state,
        accounts: [...state.accounts, action.payload],
        accountId: action.payload.id,
        transfers: [],
      };
    case "addRecurrent":
      return {
        ...state,
        recurrents: [action.payload, ...state.recurrents].sort(
          (r1, r2) =>
            r1.name.localeCompare(r2.name) ||
            (r1.comment || "").localeCompare(r2.comment || "")
        ),
      };
    case "addStatement":
      return {
        ...state,
        statements: [action.payload, ...state.statements].sort(
          (s1, s2) => s2.startDate.getTime() - s1.startDate.getTime()
        ),
      };
    case "addStatements":
      return {
        ...state,
        statements: [...state.statements, ...action.payload].sort(
          (s1, s2) => s2.startDate.getTime() - s1.startDate.getTime()
        ),
      };
    case "addTransfer":
      return {
        ...state,
        transfers: [action.payload, ...state.transfers].sort(
          (t1, t2) => t2.date.getTime() - t1.date.getTime()
        ),
      };
    case "addTransfers":
      return {
        ...state,
        transfers: [...state.transfers, ...action.payload].sort(
          (t1, t2) => t2.date.getTime() - t1.date.getTime()
        ),
      };
    case "deleteAccount":
      const accounts = state.accounts.filter(
        (account) => account.id !== action.payload
      );
      return {
        ...state,
        accounts,
        accountId:
          accounts.sort((a1, a2) => a1.name.localeCompare(a2.name))[0]?.id ||
          null,
      };
    case "deleteRecurrent":
      return {
        ...state,
        recurrents: state.recurrents.filter((r) => r.id !== action.payload),
      };
    case "deleteStatement":
      return {
        ...state,
        statements: state.statements.filter((s) => s.id !== action.payload),
      };
    case "deleteTransfer":
      return {
        ...state,
        transfers: state.transfers.filter((t) => t.id !== action.payload),
      };
    case "replaceRecurrent":
      return {
        ...state,
        recurrents: state.recurrents.map((r) =>
          r.id === action.payload.id ? action.payload : r
        ),
      };
    case "replaceStatement":
      return {
        ...state,
        statements: state.statements.map((s) =>
          s.id === action.payload.id ? action.payload : s
        ),
      };
    case "replaceTransfer":
      return {
        ...state,
        transfers: state.transfers.map((t) =>
          t.id === action.payload.id ? action.payload : t
        ),
      };
    case "setAccount":
      return {
        ...state,
        account: action.payload,
        accounts: state.accounts.map((account) =>
          account.id === action.payload?.id ? action.payload : account
        ),
      };
    case "setAccountId":
      return { ...state, accountId: action.payload, transfers: [] };
    case "setAccounts":
      return {
        ...state,
        accounts: action.payload,
        accountId:
          action.payload.sort((a1, a2) => a1.name.localeCompare(a2.name))[0]
            ?.id || null,
      };
    case "setRecurrents":
      return {
        ...state,
        recurrents: action.payload.sort(
          (r1, r2) =>
            r1.name.localeCompare(r2.name) ||
            (r1.comment || "").localeCompare(r2.comment || "")
        ),
      };
    case "setStatements":
      return {
        ...state,
        statements: action.payload,
      };
    case "setStatementsCount":
      return {
        ...state,
        statementsCount: action.payload,
      };
    case "setTransfers":
      return {
        ...state,
        transfers: action.payload,
      };
    case "setTransfersCount":
      return {
        ...state,
        transfersCount: action.payload,
      };
    default:
      throw new Error("Unknown action");
  }
};

export const AccountProvider = ({
  children,
}: PropsWithChildren): ReactElement => {
  const [state, dispatch] = useReducer(AccountReducer, defaultState);

  return (
    <AccountContext.Provider value={{ state, dispatch }}>
      {children}
    </AccountContext.Provider>
  );
};
