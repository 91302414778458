import { AxiosError } from "axios";
import { z } from "zod";

const apiErrorSchema = z.object({ errorCode: z.string() });

export const getErrorCode = (err: unknown): string | null => {
  if (err instanceof AxiosError && err.response?.status === 400) {
    const parsed = apiErrorSchema.safeParse(err.response.data);
    if (parsed.success) {
      return parsed.data.errorCode;
    }
  }

  return null;
};
