import { z } from "zod";

export const statementSchema = z.object({
  id: z.string().min(1),
  accountId: z.string().min(1),
  startDate: z.string().pipe(z.coerce.date()),
  endDate: z.string().pipe(z.coerce.date()),
  pendingIn: z.number(),
  pendingOut: z.number(),
  completedIn: z.number(),
  completedOut: z.number(),
  totalIn: z.number(),
  totalOut: z.number(),
});
