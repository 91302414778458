import { signUp } from "@aws-amplify/auth";
import { zodResolver } from "@hookform/resolvers/zod";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Dispatch, SetStateAction, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { SIGN_UP_STEP } from "./sign-up-steps.interface";

interface Props {
  setActiveStep: Dispatch<SetStateAction<SIGN_UP_STEP>>;
  setUserEmail: Dispatch<SetStateAction<string>>;
  completeSignUp: () => void;
}

const formSchema = z.object({
  email: z.string().regex(
    // eslint-disable-next-line no-control-regex
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
    "invalidEmail"
  ),
  password: z
    .string()
    .min(12, "tooShortPassword")
    .regex(/[a-z]/, "missingLowerCase")
    .regex(/[A-Z]/, "missingUpperCase")
    .regex(/[0-9]/, "missingNumber")
    .regex(/[[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]/, "missingSpecial"),
});

type FormInputs = z.infer<typeof formSchema>;

export const SignUpForm = (props: Props) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: zodResolver(formSchema),
  });
  const [errorCode, setErrorCode] = useState<string | undefined>();

  const onSubmit: SubmitHandler<FormInputs> = ({ email, password }) => {
    props.setUserEmail(email);
    signUp({ username: email, password })
      .then((res) => {
        if (res.isSignUpComplete) {
          return props.completeSignUp();
        }

        if (res.nextStep.signUpStep === "CONFIRM_SIGN_UP") {
          return props.setActiveStep(SIGN_UP_STEP.ConfirmSignUp);
        }
      })
      .catch((err) => {
        if (err instanceof Error && err.name === "UsernameExistsException") {
          setErrorCode("userAreadyExists");
          return;
        }

        setErrorCode("internalError");
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("security.texts.signUp")}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t("security.fields.email")}
                autoComplete="email"
                error={Boolean(errors.email?.message)}
                helperText={
                  errors.email?.message
                    ? t(`security.errors.${errors.email?.message}`)
                    : null
                }
                {...register("email")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t("security.fields.password")}
                type="password"
                autoComplete="new-password"
                error={Boolean(errors.password?.message)}
                helperText={
                  errors.password?.message
                    ? t(`security.errors.${errors.password?.message}`)
                    : null
                }
                {...register("password")}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {t("security.actions.signUp")}
          </Button>
        </form>
        {errorCode ? (
          <Alert severity="error">{t(`security.errors.${errorCode}`)}</Alert>
        ) : null}
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Link href="/sign-in" variant="body2">
              {t("security.texts.alreadyAccount")}
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
