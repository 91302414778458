import { Card, CardContent, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid2";
import { Fragment, useContext, useEffect, useState } from "react";

import { AccountContext } from "../../contexts";

import { AccountCard } from "./account-card";
import { AccountCardAdd } from "./account-card-add";
import { AccountEdit } from "./account-edit";
import { AccountService } from "../../services";

export const AccountsColumn = () => {
  const accountContext = useContext(AccountContext);
  const accountService = new AccountService(accountContext);
  const [addAccount, setAddAccount] = useState(false);
  const [editAccount, setEditAccount] = useState<string | null>(null);
  const sortedAccounts = accountContext.state.accounts.sort((a1, a2) =>
    a1.name.localeCompare(a2.name)
  );

  useEffect(() => {
    accountService.listAccounts();
  }, []);

  useEffect(() => {
    setAddAccount(false);
  }, [accountContext.state.accounts]);

  return (
    <Fragment>
      <Card>
        <CardContent>
          <Grid container spacing={2} size={{ xs: 12 }}>
            {sortedAccounts.map((account) => (
              <Grid size={{ xs: 12 }} key={account.id}>
                <AccountEdit
                  account={account}
                  open={editAccount === account.id}
                  handleClose={() => setEditAccount(null)}
                />
                <AccountCard account={account} />
              </Grid>
            ))}
            {addAccount ? (
              <Grid size={{ xs: 12 }}>
                <AccountCardAdd />
              </Grid>
            ) : (
              <Grid size={{ xs: 12 }} textAlign="center">
                <IconButton onClick={() => setAddAccount(true)}>
                  <AddIcon />
                </IconButton>
                <IconButton
                  onClick={() => setEditAccount(accountContext.state.accountId)}
                >
                  <EditIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Fragment>
  );
};
