import { Container } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { AccountsColumn } from "../components/account";
import { TransfersList } from "../components/transfer";
import { RightSideTabs } from "../components/right-side-tabs";

export const Home = () => {
  return (
    <Container sx={{ marginTop: 3 }} maxWidth={false}>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 4, lg: 2 }}>
          <AccountsColumn />
        </Grid>
        <Grid
          size={{ xs: 12, sm: 8, lg: 5 }}
          container
          spacing={2}
          direction="column"
          alignItems="start"
        >
          <Grid size={{ xs: 12 }}>
            <TransfersList />
          </Grid>
        </Grid>
        <Grid size={{ xs: 12, sm: 12, lg: 5 }}>
          <RightSideTabs />
        </Grid>
      </Grid>
    </Container>
  );
};
