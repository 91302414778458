import { signOut } from "aws-amplify/auth/cognito";
import axios from "axios";

export const client = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  withCredentials: true,
});

client.interceptors.response.use(
  (response) => response,
  (err) => {
    const statusCode = err.response?.status;

    if (statusCode === 401 || statusCode === 403) {
      signOut();
    }

    return Promise.reject(err);
  }
);
