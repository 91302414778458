import { IconButton, TableCell, TableRow, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Fragment, PropsWithRef, useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { AccountContext } from "../../contexts";
import { Recurrent } from "../../models";
import { dateToDateString, numberToCurrencyString } from "../../translations";
import { RecurrentEdit } from "./recurrent-edit";
import { RecurrentService } from "../../services";

export const RecurrentRow = (props: PropsWithRef<{ recurrent: Recurrent }>) => {
  const accountContext = useContext(AccountContext);
  const recurrentService = new RecurrentService(accountContext);
  const { recurrent } = props;
  const { t } = useTranslation();
  const [editRecurrent, setEditRecurrent] = useState(false);

  const onStatusUpdate = (status: boolean) => {
    recurrentService.updateRecurrentStatus(recurrent.id, status);
  };

  const onEditClick = () => {
    setEditRecurrent(true);
  };

  const onCloseClick = () => {
    setEditRecurrent(false);
  };

  return (
    <Fragment>
      <RecurrentEdit
        recurrent={recurrent}
        open={editRecurrent}
        handleClose={onCloseClick}
      />
      <TableRow>
        <TableCell component="th" scope="row" align="left">
          {t("recurrents.texts.next")} :{" "}
          {recurrent.nextDate ? dateToDateString(recurrent.nextDate) : "N/A"}
        </TableCell>
        <TableCell align="left">
          <Typography>{recurrent.name}</Typography>
          <Typography color="textDisabled">{recurrent.comment}</Typography>
        </TableCell>
        <TableCell align="right">
          <Typography
            variant="body1"
            align="right"
            color={recurrent.amount > 0 ? "success" : "error"}
          >
            {numberToCurrencyString(recurrent.amount)}
          </Typography>
        </TableCell>
        <TableCell align="right">
          {recurrent.active ? (
            <IconButton onClick={() => onStatusUpdate(false)}>
              <PauseIcon />
            </IconButton>
          ) : (
            <IconButton onClick={() => onStatusUpdate(true)}>
              <PlayArrowIcon />
            </IconButton>
          )}
          <IconButton onClick={onEditClick}>
            <EditIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};
