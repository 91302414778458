import { Recurrent } from "../models";

import { client } from "./client";
import { recurrentSchema, recurrentsSchema } from "./schemas";

export abstract class RecurrentApi {
  public static listRecurrents(accountId: string): Promise<Recurrent[]> {
    return client
      .get("/recurrents", { params: { accountId } })
      .then((res) => recurrentsSchema.parse(res.data));
  }

  public static createRecurrent(data: {
    accountId: string;
    amount: number;
    day: number;
    name: string;
    comment?: string | null;
    month1: boolean;
    month2: boolean;
    month3: boolean;
    month4: boolean;
    month5: boolean;
    month6: boolean;
    month7: boolean;
    month8: boolean;
    month9: boolean;
    month10: boolean;
    month11: boolean;
    month12: boolean;
    active: boolean;
  }): Promise<Recurrent> {
    return client
      .put("/recurrents", data)
      .then((res) => recurrentSchema.parse(res.data));
  }

  public static updateRecurrent(
    id: string,
    data: {
      amount: number;
      day: number;
      name: string;
      comment?: string | null;
      month1: boolean;
      month2: boolean;
      month3: boolean;
      month4: boolean;
      month5: boolean;
      month6: boolean;
      month7: boolean;
      month8: boolean;
      month9: boolean;
      month10: boolean;
      month11: boolean;
      month12: boolean;
      active: boolean;
    }
  ): Promise<Recurrent> {
    return client
      .post(`/recurrents/${id}`, data)
      .then((res) => recurrentSchema.parse(res.data));
  }

  public static updateRecurrentStatus(
    id: string,
    active: boolean
  ): Promise<Recurrent> {
    return client
      .post(`/recurrents/${id}/status`, { active })
      .then((res) => recurrentSchema.parse(res.data));
  }

  public static deleteRecurrent(id: string): Promise<void> {
    return client.delete(`/recurrents/${id}`).then(() => void 0);
  }
}
