import { RecurrentApi } from "../apis";
import { IAccountContext } from "../contexts";

export class RecurrentService {
  constructor(private readonly _accountContext: IAccountContext) {}

  public createRecurrent(data: {
    accountId: string;
    amount: number;
    day: number;
    name: string;
    comment?: string | null;
    month1: boolean;
    month2: boolean;
    month3: boolean;
    month4: boolean;
    month5: boolean;
    month6: boolean;
    month7: boolean;
    month8: boolean;
    month9: boolean;
    month10: boolean;
    month11: boolean;
    month12: boolean;
    active: boolean;
  }): Promise<void> {
    return RecurrentApi.createRecurrent(data)
      .then((recurrent) => {
        this._accountContext.dispatch({
          type: "addRecurrent",
          payload: recurrent,
        });
      })
      .catch((err) => console.error(err));
  }

  public updateRecurrent(
    recurrentId: string,
    data: {
      amount: number;
      day: number;
      name: string;
      comment?: string | null;
      month1: boolean;
      month2: boolean;
      month3: boolean;
      month4: boolean;
      month5: boolean;
      month6: boolean;
      month7: boolean;
      month8: boolean;
      month9: boolean;
      month10: boolean;
      month11: boolean;
      month12: boolean;
      active: boolean;
    }
  ): Promise<void> {
    return RecurrentApi.updateRecurrent(recurrentId, data)
      .then((recurrent) => {
        this._accountContext.dispatch({
          type: "replaceRecurrent",
          payload: recurrent,
        });
      })
      .catch((err) => console.error(err));
  }

  public updateRecurrentStatus(
    recurrentId: string,
    status: boolean
  ): Promise<void> {
    return RecurrentApi.updateRecurrentStatus(recurrentId, status)
      .then((recurrent) => {
        this._accountContext.dispatch({
          type: "replaceRecurrent",
          payload: recurrent,
        });
      })
      .catch((err) => console.error(err));
  }

  public delete(recurrentId: string): Promise<void> {
    return RecurrentApi.deleteRecurrent(recurrentId)
      .then(() => {
        this._accountContext.dispatch({
          type: "deleteRecurrent",
          payload: recurrentId,
        });
      })
      .catch((err) => console.error(err));
  }

  public listRecurrents(accountId: string): Promise<void> {
    return RecurrentApi.listRecurrents(accountId)
      .then((recurrents) => {
        this._accountContext.dispatch({
          type: "setRecurrents",
          payload: recurrents,
        });
      })
      .catch((err) => console.error(err));
  }

  public emptyRecurrents(): void {
    this._accountContext.dispatch({ type: "setRecurrents", payload: [] });
  }
}
