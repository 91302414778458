import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PropsWithoutRef, useContext } from "react";
import { NumericFormat } from "react-number-format";
import { z } from "zod";

import { AccountContext } from "../../contexts";
import { parseCurrency } from "../../helpers";
import { TransferService } from "../../services";

const formSchema = z.object({
  date: z.date(),
  amount: z.string(),
  name: z.string(),
  comment: z.string().optional(),
  done: z.boolean(),
});

type FormInputs = z.infer<typeof formSchema>;

export const TransferAdd = (
  props: PropsWithoutRef<{
    accountId: string;
    open: boolean;
    handleClose: () => void;
  }>
) => {
  const accountContext = useContext(AccountContext);
  const transferService = new TransferService(accountContext);
  const { t } = useTranslation();
  const { accountId, open, handleClose } = props;
  const { register, handleSubmit, setValue, resetField } = useForm<FormInputs>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      date: new Date(),
    },
  });

  const onSubmit: SubmitHandler<FormInputs> = (fields) => {
    const amount = parseCurrency(fields.amount);
    transferService
      .createTransfer({
        accountId: accountId,
        ...fields,
        amount: amount,
      })
      .then(() => {
        resetField("name");
        resetField("date");
        resetField("comment");
        resetField("amount");
        handleClose();
      });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>{t("transfers.texts.newTransfer")}</DialogTitle>
          <DialogContent>
            <Grid spacing={2}>
              <Grid size={{ xs: 12 }} sx={{ mt: 1 }}>
                <TextField
                  fullWidth
                  label={t("transfers.fields.name")}
                  required={true}
                  {...register("name")}
                />
              </Grid>
              <Grid size={{ xs: 12 }} sx={{ mt: 1 }}>
                <TextField
                  fullWidth
                  label={t("transfers.fields.comment")}
                  {...register("comment")}
                />
              </Grid>
              <Grid size={{ xs: 12 }} sx={{ mt: 1 }}>
                <NumericFormat
                  fullWidth
                  label={t("transfers.fields.amount")}
                  suffix=" €"
                  thousandSeparator=" "
                  decimalSeparator=","
                  customInput={TextField}
                  required={true}
                  inputProps={{ ...register("amount") }}
                />
              </Grid>
              <Grid size={{ xs: 12 }} sx={{ mt: 1 }}>
                <DatePicker
                  defaultValue={DateTime.now()}
                  disableFuture={true}
                  required={true}
                  {...register("date")}
                  onChange={(v) => v && setValue("date", v.toJSDate())}
                />
              </Grid>
              <Grid size={{ xs: 12 }} sx={{ mt: 1 }}>
                <FormControlLabel
                  control={<Switch {...register("done")} />}
                  label={t("transfers.fields.done")}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>
              {t("transfers.actions.cancel")}
            </Button>
            <Button type="submit">{t("transfers.actions.add")}</Button>
          </DialogActions>
        </form>
      </LocalizationProvider>
    </Dialog>
  );
};
