import { getCurrentUser } from "aws-amplify/auth";

import { User } from "../models";
import { client } from "./client";
import { userSchema } from "./schemas";

export abstract class SecurityApi {
  public static getCurrentUser(): Promise<User> {
    return getCurrentUser()
      .then(() => {
        return client.get("/security/user");
      })
      .then((res) => {
        return userSchema.parse(res.data);
      });
  }
}
