import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { DateTime } from "luxon";
import { PropsWithoutRef, useContext } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { z } from "zod";

import { AccountContext } from "../../contexts";
import { getCurrency, parseCurrency } from "../../helpers";
import { Recurrent } from "../../models";
import { dateToMonthShort } from "../../translations";
import { RecurrentService } from "../../services";

const formSchema = z.object({
  day: z.number().int().min(1).max(28),
  amount: z.string(),
  name: z.string().min(1),
  comment: z.string().nullish(),
  month1: z.boolean(),
  month2: z.boolean(),
  month3: z.boolean(),
  month4: z.boolean(),
  month5: z.boolean(),
  month6: z.boolean(),
  month7: z.boolean(),
  month8: z.boolean(),
  month9: z.boolean(),
  month10: z.boolean(),
  month11: z.boolean(),
  month12: z.boolean(),
  active: z.boolean(),
});

type FormInputs = z.infer<typeof formSchema> & {
  [key: `month${number}`]: boolean;
};

export const RecurrentEdit = (
  props: PropsWithoutRef<{
    recurrent: Recurrent;
    open: boolean;
    handleClose: () => void;
  }>
) => {
  const accountContext = useContext(AccountContext);
  const recurrentService = new RecurrentService(accountContext);
  const { t } = useTranslation();
  const { recurrent, open, handleClose } = props;
  const { register, handleSubmit } = useForm<FormInputs>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      day: recurrent.day,
      amount: getCurrency(recurrent.amount),
      name: recurrent.name,
      comment: recurrent.comment,
      month1: recurrent.month1,
      month2: recurrent.month2,
      month3: recurrent.month3,
      month4: recurrent.month4,
      month5: recurrent.month5,
      month6: recurrent.month6,
      month7: recurrent.month7,
      month8: recurrent.month8,
      month9: recurrent.month9,
      month10: recurrent.month10,
      month11: recurrent.month11,
      month12: recurrent.month12,
      active: recurrent.active,
    },
  });

  const onSubmit: SubmitHandler<FormInputs> = (fields) => {
    const amount = parseCurrency(fields.amount);
    recurrentService
      .updateRecurrent(recurrent.id, { ...fields, amount })
      .then(() => {
        handleClose();
      });
  };

  const handleDelete = () => {
    recurrentService.delete(recurrent.id).then(() => {
      handleClose();
    });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{t("recurrents.texts.editRecurrent")}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12 }} sx={{ mt: 1 }}>
              <TextField
                fullWidth
                defaultValue={recurrent.name}
                label={t("recurrents.fields.name")}
                required={true}
                {...register("name")}
              />
            </Grid>
            <Grid size={{ xs: 12 }} sx={{ mt: 1 }}>
              <TextField
                fullWidth
                defaultValue={recurrent.comment}
                label={t("recurrents.fields.comment")}
                {...register("comment")}
              />
            </Grid>
            <Grid size={{ xs: 12 }} sx={{ mt: 1 }}>
              <NumericFormat
                fullWidth
                defaultValue={recurrent.amount}
                label={t("recurrents.fields.amount")}
                suffix=" €"
                thousandSeparator=" "
                decimalSeparator=","
                customInput={TextField}
                required={true}
                inputProps={{ ...register("amount") }}
              />
            </Grid>
            <Grid size={{ xs: 12 }} sx={{ mt: 1 }}>
              <Select
                fullWidth
                defaultValue={recurrent.day}
                label={t("recurrents.fields.day")}
                {...register("day")}
              >
                {Array(28)
                  .fill(null)
                  .map((_, i) => (
                    <MenuItem key={`recurrent_edit_day_${i}`} value={i + 1}>
                      {t(`recurrents.fields.daySelect`, { day: i + 1 })}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid container size={{ xs: 12 }} sx={{ mt: 1 }}>
              {Array(12)
                .fill(null)
                .map((_, i) => (
                  <Grid key={`recurrent_edit_month_${i}`} size={{ xs: 4 }}>
                    <FormControlLabel
                      control={
                        <Switch
                          defaultChecked={recurrent[`month${i + 1}`]}
                          {...register(`month${i + 1}`)}
                        />
                      }
                      label={dateToMonthShort(
                        DateTime.fromObject({ month: i + 1 }).toJSDate()
                      )}
                    />
                  </Grid>
                ))}
            </Grid>
            <Grid size={{ xs: 12 }} sx={{ mt: 1 }}>
              <FormControlLabel
                control={
                  <Switch
                    defaultChecked={recurrent.active}
                    {...register("active")}
                  />
                }
                label={t("recurrents.fields.active")}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            {t("recurrents.actions.cancel")}
          </Button>
          <Button onClick={handleDelete} color="error">
            {t("recurrents.actions.delete")}
          </Button>
          <Button type="submit">{t("recurrents.actions.save")}</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
