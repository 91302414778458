import {
  Box,
  ButtonBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { Fragment, useContext, useEffect, useState } from "react";

import { AccountContext } from "../../contexts";
import { useTranslation } from "react-i18next";
import { RecurrentRow } from "./recurrent-row";
import { RecurrentAdd } from "./recurrent-add";
import { RecurrentService } from "../../services";

export const RecurrentsList = () => {
  const accountContext = useContext(AccountContext);
  const recurrentService = new RecurrentService(accountContext);
  const { t } = useTranslation();

  const { accountId } = accountContext.state;
  const [addRecurrent, setAddRecurrent] = useState(false);

  useEffect(() => {
    recurrentService.emptyRecurrents();
    if (accountId) {
      recurrentService.listRecurrents(accountId);
    }
  }, [accountId]);

  if (!accountId) {
    return null;
  }

  const onAddClick = () => {
    setAddRecurrent(true);
  };

  const onCloseClick = () => {
    setAddRecurrent(false);
  };

  return (
    <Fragment>
      <RecurrentAdd
        accountId={accountId}
        open={addRecurrent}
        handleClose={onCloseClick}
      />
      <TableContainer
        component={Box}
        sx={{
          overflow: "scroll",
          scrollbarWidth: "none",
        }}
      >
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell colSpan={4}>
                <ButtonBase sx={{ width: "100%" }} onClick={() => onAddClick()}>
                  {t("recurrents.actions.add")}
                </ButtonBase>
              </TableCell>
            </TableRow>
            {accountContext.state.recurrents.map((recurrent) => (
              <RecurrentRow key={recurrent.id} recurrent={recurrent} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
};
