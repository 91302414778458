import { Amplify } from "aws-amplify";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";
import { CookieStorage } from "aws-amplify/utils";
import React from "react";
import ReactDOM from "react-dom/client";

import "./translations";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_USERPOOL_ID || "",
      userPoolClientId: process.env.REACT_APP_USERPOOL_CLIENT_ID || "",
    },
  },
});
cognitoUserPoolsTokenProvider.setKeyValueStorage(
  new CookieStorage({
    domain: process.env.REACT_APP_DOMAIN_NAME,
    secure: true,
    sameSite: "strict",
    expires: 1,
  })
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
