import { AxiosHeaders } from "axios";

import { Statement } from "../models";

import { statementSchema, statementsSchema } from "./schemas/statements";
import { client } from "./client";

export abstract class StatementApi {
  public static listStatements(
    accountId: string,
    pagination: {
      limit: number;
      skip: number;
      order: Record<string, "ASC" | "DESC">;
    }
  ): Promise<{ data: Statement[]; total: number }> {
    return client
      .get("/statements", {
        params: { accountId, ...pagination },
      })
      .then(async (res) => {
        const data = statementsSchema.parse(res.data);
        const total = parseInt(
          (res.headers as AxiosHeaders).get("x-total") as string
        );
        return { data, total };
      });
  }

  public static getStatement(statementId: string): Promise<Statement> {
    return client
      .get(`/statements/${statementId}`)
      .then(async (res) => statementSchema.parse(res.data));
  }

  public static createStatement(data: {
    accountId: string;
    startDate: Date;
    endDate: Date;
  }): Promise<Statement> {
    return client
      .put("/statements", data)
      .then((res) => statementSchema.parse(res.data));
  }

  public static updateStatement(
    statementId: string,
    data: { startDate: Date; endDate: Date }
  ): Promise<Statement> {
    return client
      .post(`/statements/${statementId}`, data)
      .then((res) => statementSchema.parse(res.data));
  }

  public static deleteStatement(statementId: string): Promise<void> {
    return client.delete(`/statements/${statementId}`).then(() => void 0);
  }
}
