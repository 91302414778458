import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { PropsWithoutRef, useContext } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { AccountContext } from "../../contexts";
import { Account } from "../../models";
import { AccountService } from "../../services";

const formSchema = z.object({
  name: z.string(),
});

type FormInputs = z.infer<typeof formSchema>;

export const AccountEdit = (
  props: PropsWithoutRef<{
    account: Account;
    open: boolean;
    handleClose: () => void;
  }>
) => {
  const accountContext = useContext(AccountContext);
  const accountService = new AccountService(accountContext);
  const { t } = useTranslation();
  const { account, open, handleClose } = props;
  const { register, handleSubmit } = useForm<FormInputs>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: account.name,
    },
  });

  const onSubmit: SubmitHandler<FormInputs> = (fields) => {
    accountService
      .updateAccount(account.id, { name: fields.name })
      .then(() => handleClose());
  };

  const handleDelete = () => {
    accountService.deleteAccount(account.id).then(() => handleClose());
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{t("accounts.texts.editAccount")}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12 }} sx={{ mt: 1 }}>
              <TextField
                fullWidth
                defaultValue={account.name}
                label={t("accounts.fields.name")}
                required={true}
                {...register("name")}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("accounts.actions.cancel")}</Button>
          <Button onClick={handleDelete} color="error">
            {t("accounts.actions.delete")}
          </Button>
          <Button type="submit">{t("accounts.actions.save")}</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
