import { fetchAuthSession, getCurrentUser, signOut } from "aws-amplify/auth";
import {
  PropsWithChildren,
  ReactElement,
  createContext,
  useEffect,
  useReducer,
} from "react";
import { useNavigate } from "react-router-dom";

import { SecurityApi } from "../apis";
import { User } from "../models";

type SetUser = { type: "setUser"; payload: User };
type UnsetUser = { type: "unsetUser" };
type Action = SetUser | UnsetUser;

interface UserState {
  user: User | null;
}
const defaultState: UserState = { user: null };

export interface IAuthContext {
  state: UserState;
  dispatch: React.Dispatch<Action>;
}

export const AuthContext = createContext<IAuthContext>({
  state: defaultState,
  dispatch: () => {},
});

const AuthReducer = (state: UserState, action: Action): UserState => {
  switch (action.type) {
    case "setUser":
      return { ...state, user: action.payload };
    case "unsetUser":
      return { ...state, user: null };
    default:
      throw new Error("Unknown action");
  }
};

export const AuthProvider = ({ children }: PropsWithChildren): ReactElement => {
  const [state, dispatch] = useReducer(AuthReducer, defaultState);
  const navigate = useNavigate();

  useEffect(() => {
    async function getUser() {
      fetchAuthSession()
        .then((session) => {
          if (!session.tokens?.accessToken) {
            throw new Error("No auth session");
          }
        })
        .then(() => getCurrentUser())
        .then(() => {
          return SecurityApi.getCurrentUser();
        })
        .then((user) => {
          dispatch({ type: "setUser", payload: user });
        })
        .catch(() => {
          dispatch({ type: "unsetUser" });
          navigate("sign-in");
        });
    }

    if (["/sign-in", "/sign-up"].includes(window.location.pathname)) {
      signOut().then(() => {
        dispatch({ type: "unsetUser" });
      });
    } else {
      getUser();
    }
  }, [navigate]);

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
