import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { PropsWithoutRef, useContext } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { AccountContext } from "../../contexts";
import { StatementService } from "../../services";

const formSchema = z.object({
  startDate: z.date(),
  endDate: z.date(),
});

type FormInputs = z.infer<typeof formSchema>;

export const StatementAdd = (
  props: PropsWithoutRef<{
    accountId: string;
    open: boolean;
    handleClose: () => void;
  }>
) => {
  const accountContext = useContext(AccountContext);
  const statementService = new StatementService(accountContext);
  const { t } = useTranslation();
  const { accountId, open, handleClose } = props;
  const { register, handleSubmit, setValue } = useForm<FormInputs>({
    resolver: zodResolver(formSchema),
  });

  const onSubmit: SubmitHandler<FormInputs> = (fields) => {
    statementService.createStatement({ ...fields, accountId }).then(() => {
      handleClose();
    });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>{t("statements.texts.newStatement")}</DialogTitle>
          <DialogContent>
            <Grid spacing={2}>
              <Grid size={{ xs: 12 }} sx={{ mt: 1 }}>
                <DatePicker
                  required={true}
                  {...register("startDate")}
                  onChange={(v) => v && setValue("startDate", v.toJSDate())}
                />
              </Grid>
              <Grid size={{ xs: 12 }} sx={{ mt: 1 }}>
                <DatePicker
                  required={true}
                  {...register("endDate")}
                  onChange={(v) => v && setValue("endDate", v.toJSDate())}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>
              {t("statements.actions.cancel")}
            </Button>
            <Button type="submit">{t("statements.actions.add")}</Button>
          </DialogActions>
        </form>
      </LocalizationProvider>
    </Dialog>
  );
};
