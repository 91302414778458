import { Fragment, PropsWithRef, useContext, useState } from "react";
import { IconButton, TableCell, TableRow, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";

import { AccountContext } from "../../contexts";
import { Transfer } from "../../models";
import { dateToDateString, numberToCurrencyString } from "../../translations";
import { TransferService } from "../../services";

import { TransferEdit } from "./transfer-edit";

export const TransferRow = ({
  transfer,
}: PropsWithRef<{ transfer: Transfer }>) => {
  const accountContext = useContext(AccountContext);
  const transferService = new TransferService(accountContext);
  const [editTransfer, setEditTransfer] = useState(false);

  const onStatusUpdate = (status: boolean) => {
    transferService.updateTransferStatus(transfer.id, status);
  };

  const onEditClick = () => {
    setEditTransfer(true);
  };

  const onCloseClick = () => {
    setEditTransfer(false);
  };

  return (
    <Fragment>
      <TransferEdit
        transfer={transfer}
        open={editTransfer}
        handleClose={onCloseClick}
      />
      <TableRow>
        <TableCell component="th" scope="row" align="left">
          {dateToDateString(transfer.date)}
        </TableCell>
        <TableCell align="left">
          <Typography>{transfer.name}</Typography>
          <Typography color="textDisabled">{transfer.comment}</Typography>
        </TableCell>
        <TableCell align="right">
          <Typography
            variant="body1"
            align="right"
            color={transfer.amount > 0 ? "success" : "error"}
          >
            {numberToCurrencyString(transfer.amount)}
          </Typography>
        </TableCell>
        <TableCell align="right">
          {transfer.done ? (
            <IconButton onClick={() => onStatusUpdate(false)}>
              <CheckCircleOutlineIcon color="success" />
            </IconButton>
          ) : (
            <IconButton onClick={() => onStatusUpdate(true)}>
              <HourglassTopIcon color="warning" />
            </IconButton>
          )}
          <IconButton onClick={onEditClick}>
            <EditIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};
