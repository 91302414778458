import { confirmSignUp } from "@aws-amplify/auth";
import { zodResolver } from "@hookform/resolvers/zod";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

interface Props {
  email: string;
  completeSignUp: () => void;
}

const formSchema = z.object({
  email: z.string(),
  code: z.string().regex(/[0-9]{6}/, "invalidCodeLength"),
});

type FormInputs = z.infer<typeof formSchema>;

export const ConfirmSignUpForm = (props: Props) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>({
    defaultValues: { email: props.email },
    resolver: zodResolver(formSchema),
  });
  const [errorCode, setErrorCode] = useState<string | undefined>();

  const onSubmit: SubmitHandler<FormInputs> = ({ email, code }) => {
    confirmSignUp({ username: email, confirmationCode: code })
      .then((res) => {
        if (res.isSignUpComplete) {
          return props.completeSignUp();
        }
      })
      .catch((err) => {
        setErrorCode("internalError");
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t("security.texts.confirmSignUp")}
        </Typography>
        <Alert severity="info">{t("security.texts.confirmMailSent")}</Alert>
        <br />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t("security.fields.email")}
                autoComplete="email"
                disabled
                {...register("email")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t("security.fields.code")}
                error={Boolean(errors.code?.message)}
                helperText={
                  errors.code?.message
                    ? t(`security.errors.${errors.code?.message}`)
                    : null
                }
                {...register("code")}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {t("security.actions.confirmSignUp")}
          </Button>
          {errorCode ? (
            <Alert severity="error">{t(`security.errors.${errorCode}`)}</Alert>
          ) : null}
        </form>
      </Box>
    </Container>
  );
};
