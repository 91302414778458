import { z } from "zod";

export const transferSchema = z.object({
  id: z.string(),
  accountId: z.string(),
  date: z.string().pipe(z.coerce.date()),
  amount: z.number(),
  name: z.string(),
  comment: z.string().nullable(),
  done: z.boolean(),
});
