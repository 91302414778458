import {
  ButtonBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { AccountContext } from "../../contexts";
import { StatementAdd } from "./statement-add";
import { StatementRow } from "./statement-row";
import { StatementService } from "../../services";

const CHUNK_SIZE = 10;

export const StatementsList = () => {
  const accountContext = useContext(AccountContext);
  const statementService = new StatementService(accountContext);
  const { t } = useTranslation();

  const { accountId, statementsCount } = accountContext.state;
  const [addStatement, setAddStatement] = useState(false);

  const onAddClick = () => {
    setAddStatement(true);
  };

  const onCloseClick = () => {
    setAddStatement(false);
  };

  useEffect(() => {
    if (accountId) {
      statementService.listStatements(accountId, {
        limit: CHUNK_SIZE,
        skip: 0,
        order: { startDate: "DESC" },
      });
    } else {
      statementService.emptyStatements();
    }
  }, [accountId]);

  if (!accountId) {
    return null;
  }

  const onGetMoreClick = () => {
    statementService.listStatements(accountId, {
      limit: CHUNK_SIZE,
      skip: accountContext.state.statements.length,
      order: { startDate: "DESC" },
    });
  };

  return (
    <Fragment>
      <StatementAdd
        accountId={accountId}
        open={addStatement}
        handleClose={onCloseClick}
      />
      <TableContainer>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell colSpan={9}>
                <ButtonBase sx={{ width: "100%" }} onClick={() => onAddClick()}>
                  {t("statements.actions.add")}
                </ButtonBase>
              </TableCell>
            </TableRow>
            {accountContext.state.statements.map((statement) => (
              <StatementRow key={statement.id} statement={statement} />
            ))}
            {accountContext.state.statements.length < statementsCount ? (
              <TableRow>
                <TableCell colSpan={9}>
                  <ButtonBase
                    sx={{ width: "100%" }}
                    onClick={() => onGetMoreClick()}
                  >
                    {t("statements.actions.showMore")}
                  </ButtonBase>
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
};
