import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import EditIcon from "@mui/icons-material/Edit";
import { Fragment, PropsWithRef, useState } from "react";

import { Statement } from "../../models";
import { dateToDateString, numberToCurrencyString } from "../../translations";
import { StatementEdit } from "./statement-edit";

export const StatementRow = (props: PropsWithRef<{ statement: Statement }>) => {
  const { statement } = props;
  const [editStatement, setEditStatement] = useState(false);

  const onEditClick = () => {
    setEditStatement(true);
  };

  const onCloseClick = () => {
    setEditStatement(false);
  };

  return (
    <Fragment>
      <StatementEdit
        statement={statement}
        open={editStatement}
        handleClose={onCloseClick}
      />
      <TableRow>
        <TableCell component="th" scope="row" align="left">
          {dateToDateString(statement.startDate)}
        </TableCell>
        <TableCell component="th" scope="row" align="left">
          {statement.endDate ? dateToDateString(statement.endDate) : "-"}
        </TableCell>
        <TableCell>
          <Box>
            <Typography display="inline" color="success">
              {numberToCurrencyString(statement.totalIn)}
            </Typography>
            <Typography display="inline" color="textDisabled" ml={1}>
              ({numberToCurrencyString(statement.pendingIn)})
            </Typography>
          </Box>
          <Box>
            <Typography display="inline" color="error">
              {numberToCurrencyString(statement.totalOut)}
            </Typography>
            <Typography display="inline" color="textDisabled" ml={1}>
              ({numberToCurrencyString(statement.pendingOut)})
            </Typography>
          </Box>
        </TableCell>
        <TableCell>
          <DoubleArrowIcon color="action" />
        </TableCell>
        <TableCell>
          <Typography
            color={
              statement.totalIn + statement.totalOut > 0 ? "success" : "error"
            }
          >
            {numberToCurrencyString(statement.totalIn + statement.totalOut)}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <IconButton onClick={onEditClick}>
            <EditIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};
