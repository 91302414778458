import { z } from "zod";

export const recurrentSchema = z.object({
  id: z.string().min(1),
  accountId: z.string().min(1),
  day: z.number().int().min(1).max(28),
  amount: z.number(),
  name: z.string().min(1),
  comment: z.string().nullish(),
  month1: z.boolean(),
  month2: z.boolean(),
  month3: z.boolean(),
  month4: z.boolean(),
  month5: z.boolean(),
  month6: z.boolean(),
  month7: z.boolean(),
  month8: z.boolean(),
  month9: z.boolean(),
  month10: z.boolean(),
  month11: z.boolean(),
  month12: z.boolean(),
  active: z.boolean(),
  nextDate: z.coerce.date().nullable(),
});
