import { zodResolver } from "@hookform/resolvers/zod";
import { Button, TextField } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useContext } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { z } from "zod";

import { AccountContext } from "../../contexts";
import { parseCurrency } from "../../helpers";
import { AccountService } from "../../services";

const formSchema = z.object({
  name: z.string(),
  amount: z.string(),
});

type FormInputs = z.infer<typeof formSchema>;

export const AccountCardAdd = () => {
  const accountContext = useContext(AccountContext);
  const accountService = new AccountService(accountContext);
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm<FormInputs>({
    resolver: zodResolver(formSchema),
  });

  const onSubmit: SubmitHandler<FormInputs> = (fields) => {
    const amount = parseCurrency(fields.amount);
    accountService.createAccount({ ...fields, amount });
  };

  return (
    <Card>
      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            fullWidth
            label={t("accounts.fields.name")}
            {...register("name")}
          />
          <NumericFormat
            fullWidth
            label={t("accounts.fields.initialAmount")}
            suffix=" €"
            thousandSeparator=" "
            decimalSeparator=","
            customInput={TextField}
            inputProps={{ ...register("amount") }}
            sx={{ mt: 1 }}
          />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 2 }}>
            {t("accounts.actions.add")}
          </Button>
        </form>
      </CardContent>
    </Card>
  );
};
