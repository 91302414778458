import { CardActionArea, CardHeader } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { PropsWithoutRef, useContext } from "react";

import { Account } from "../../models";
import { AccountContext } from "../../contexts";
import { numberToCurrencyString } from "../../translations";
import { AccountService } from "../../services";

export const AccountCard = (
  props: PropsWithoutRef<{
    account: Account;
  }>
) => {
  const accountContext = useContext(AccountContext);
  const accountService = new AccountService(accountContext);
  const { account } = props;

  const onClick = () => {
    if (accountContext.state.accountId === props.account.id) {
      return;
    }

    accountService.switchAccount(props.account.id);
  };

  return (
    <Card raised={account.id === accountContext.state.accountId}>
      <CardActionArea onClick={onClick}>
        <CardHeader title={account.name} />
        <CardContent>
          <Typography
            variant="body1"
            align="right"
            color={account.amount > 0 ? "success" : "error"}
          >
            {numberToCurrencyString(account.amount)}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
