import {
  ButtonBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { Fragment, useContext, useEffect, useState } from "react";

import { AccountContext } from "../../contexts";
import { TransferService } from "../../services";

import { TransferRow } from "./transfer-row";
import { useTranslation } from "react-i18next";
import { TransferAdd } from "./transfer-add";

const CHUNK_SIZE = 10;

export const TransfersList = () => {
  const accountContext = useContext(AccountContext);
  const transferService = new TransferService(accountContext);
  const { t } = useTranslation();

  const { accountId } = accountContext.state;
  const [addTransfer, setAddTransfer] = useState(false);

  useEffect(() => {
    transferService.emptyTransfers();
    if (accountId) {
      transferService.listTransfers(accountId, {
        limit: CHUNK_SIZE,
        skip: 0,
        order: { date: "DESC" },
      });
    }
  }, [accountId]);

  if (!accountId) {
    return null;
  }

  const onGetMoreClick = () => {
    transferService.listTransfers(accountId, {
      limit: CHUNK_SIZE,
      skip: accountContext.state.transfers.length,
      order: { date: "DESC" },
    });
  };

  const onAddClick = () => {
    setAddTransfer(true);
  };

  const onCloseClick = () => {
    setAddTransfer(false);
  };

  return (
    <Fragment>
      <TransferAdd
        accountId={accountId}
        open={addTransfer}
        handleClose={onCloseClick}
      />
      <TableContainer
        component={Paper}
        sx={{
          overflow: "scroll",
          scrollbarWidth: "none",
        }}
      >
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell colSpan={4}>
                <ButtonBase sx={{ width: "100%" }} onClick={() => onAddClick()}>
                  {t("transfers.actions.add")}
                </ButtonBase>
              </TableCell>
            </TableRow>
            {accountContext.state.transfers.map((transfer) => (
              <TransferRow key={transfer.id} transfer={transfer} />
            ))}
            {accountContext.state.transfers.length <
            accountContext.state.transfersCount ? (
              <TableRow>
                <TableCell colSpan={4}>
                  <ButtonBase
                    sx={{ width: "100%" }}
                    onClick={() => onGetMoreClick()}
                  >
                    {t("transfers.actions.showMore")}
                  </ButtonBase>
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
};
