import { AxiosHeaders } from "axios";

import { Transfer } from "../models";

import { client } from "./client";
import { transferSchema, transfersSchema } from "./schemas";

export abstract class TransfersApi {
  public static listTransfers(
    accountId: string,
    pagination: {
      limit: number;
      skip: number;
      order: Record<string, "ASC" | "DESC">;
    }
  ): Promise<{ transfers: Transfer[]; total: number }> {
    return client
      .get(`/transfers`, { params: { accountId, ...pagination } })
      .then(async (res) => {
        const transfers = transfersSchema.parse(res.data);
        const total = parseInt(
          (res.headers as AxiosHeaders).get("x-total") as string
        );
        return { transfers, total };
      });
  }

  public static createTransfer(data: {
    accountId: string;
    name: string;
    comment?: string;
    date: Date;
    done: boolean;
    amount: number;
  }): Promise<Transfer> {
    return client
      .put("/transfers", data)
      .then((res) => transferSchema.parse(res.data));
  }

  public static updateTransfer(
    transferId: string,
    data: {
      name: string;
      comment?: string;
      date: Date;
      done: boolean;
      amount: number;
    }
  ): Promise<Transfer> {
    return client
      .post(`/transfers/${transferId}`, data)
      .then((res) => transferSchema.parse(res.data));
  }

  public static updateTransferStatus(
    transferId: string,
    status: boolean
  ): Promise<Transfer> {
    return client
      .post(`/transfers/${transferId}/status`, { status })
      .then((res) => transferSchema.parse(res.data));
  }

  public static deleteTransfer(transferId: string): Promise<void> {
    return client.delete(`/transfers/${transferId}`).then(() => void 0);
  }
}
