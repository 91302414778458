import { DateTime } from "luxon";

export function numberToCurrencyString(value: number): string {
  return value.toLocaleString("fr-FR", {
    style: "currency",
    currency: "EUR",
  });
}

export function dateToDateString(value: Date): string {
  return DateTime.fromJSDate(value).toLocaleString(DateTime.DATE_MED, {
    locale: "fr-FR",
  });
}

export function dateToMonthShort(value: Date): string {
  const dt = DateTime.fromJSDate(value);
  if (!dt.isValid) {
    throw new Error("Invalid datetime");
  }

  return dt.monthShort;
}
